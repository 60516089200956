html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* date picker style start */
.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
}

.ant-picker-panels > *:last-child {
    display: none;
}

.ant-picker-panel-container,
.ant-picker-footer {
    width: 280px !important;
    z-index: 9999999999 !important;
}

.ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
}
/* date picker style end */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
.common-bg {
    background: #005ded;
    color: #fff;
}
.system-title {
    margin: 0;
    font-size: 32px;
    color: #fff;
    font-weight: 600;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* layout page design start */
.ant-menu-root {
    background: #1677ff;
    color: #fff;
}
.ant-layout-sider-has-trigger {
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
}
.ant-layout-sider-has-trigger > .ant-layout-sider-trigger {
    width: 250px !important;
}
.ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
}
.ant-layout-sider-collapsed > .ant-layout-sider-trigger {
    width: 80px !important;
}
.ant-layout-sider-children {
    position: fixed;
    max-width: 250px;
    min-width: 250px;
    width: 250px;
    transition: all 0.05s ease-in-out;
    overflow: auto;
    background: #1677ff;
}
.ant-layout-sider-trigger,
.ant-menu-sub {
    background: #0150a5 !important;
}
.ant-layout-sider-collapsed > .ant-layout-sider-children {
    max-width: 80px;
    min-width: 80px;
    width: 80px;
}
.user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}
.global-loading {
    opacity: 0.5;
    pointer-events: none;
}
.ant-menu-item-active {
    color: #fff !important;
    background-color: #1f73f2 !important;
}
.ant-menu-submenu-active {
    background-color: #1f73f2 !important;
}
.ant-menu-submenu-selected {
    background: #1f73f2 !important;
}
.ant-menu-item-selected {
    background-color: white !important;
    color: #005ded !important;
}
/* layout page design end */

/* login page start */
.login-form-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-form {
    width: 400px;
}

.login-form > .ant-form-item {
    margin-bottom: 14px !important;
}

.login-form-forgot {
    float: right;
}
.ant-col-rtl .login-form-forgot {
    float: left;
}
.login-form-button {
    width: 100%;
}
.login-input-field-customize {
    background: #f7f7f7;
    padding: 8px 15px;
    border-left: 4px solid #2872f0 !important;
    border-radius: 2px;
}
.login-form-button {
    border-radius: 25px;
    height: 36px;
    font-weight: 600;
}
.login-left-site {
    background-image: url("./assets/Portal\ Login.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-semi-title {
    color: #efe8e8;
    font-size: 20px;
    font-family: "Poppins";
    font-weight: 600;
    margin: 0;
}
.login-title {
    color: #fff;
    font-size: 60px;
    font-family: "Poppins";
    font-weight: 600;
    margin: 0 0 10px 0;
}
@media screen and (max-width: 992px) {
    .login-left-site-container {
        display: none;
    }
}
/* login page end */

/* filter page start */
.filter-calender,
.filter-btn {
    width: 100%;
}
/* filter page end */

/* tooltip style */
.custom-tooltip {
    background: linear-gradient(90deg, #9a94ee 0%, #1c55f1 100%);
    color: #fff;
    padding: 8px 12px;
    border-radius: 10px;
}
.custom-tooltip > h6,
.custom-tooltip > p {
    margin: 0;
    padding: 0;
    font-size: 18px;
}
.custom-tooltip > p {
    font-size: 16px;
}

/* common heading and box style start */
.box-container {
    min-height: 175px;
}
.box-container,
.component-box-container {
    border-radius: 10px;
    box-shadow: 0px 0px 2px 0px #00000040;
}
.component-box-container {
    margin-top: 15px;
}
.box-heading {
    background: #0050a4;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    padding: 10px;
    color: #fff;
    text-align: center;
}
.box-heading-dash {
    background: none;
    color: #0050a4;
    padding: 5px 10px;
    text-align: left;
}
.box-body {
    padding: 10px 5px 15px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box-footer-container {
    text-align: center;
    padding: 0 0 5px 0;
}
.box-footer-content {
    color: #0050a4;
    margin: 0;
    font-weight: 600;
    font-size: 12px;
}
.box-footer-content > span {
    font-weight: 600;
}
/* common heading and box style end */

/* menu scrollbar customize start */

/* Works on Firefox */
.ant-layout-sider-children {
    scrollbar-width: thin !important;
    scrollbar-color: blue !important;
}

/* Works on Chrome, Edge, and Safari */
.ant-layout-sider-children::-webkit-scrollbar {
    width: 0px !important;
}
.ant-layout-sider-children:hover::-webkit-scrollbar {
    width: 3px !important;
    position: absolute;
}

.ant-layout-sider-children::-webkit-scrollbar-track {
    background: #1677ff !important;
}

.ant-layout-sider-children::-webkit-scrollbar-thumb {
    background-color: blue !important;
    border-radius: 20px !important;
    border: 1px solid #fff !important;
}
/* menu scrollbar customize end */

/* file upload style */
/* file upload button */
.training-file::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
}

/* file upload button hover state */
.training-file::file-selector-button:hover {
    background-color: #f3f4f6;
}

/* file upload button active state */
.training-file::file-selector-button:active {
    background-color: #e5e7eb;
}

/* data management start */
.data-box {
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgb(221, 220, 220);
}
/* data management end */

/* profile component style */
.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.profile-card {
    position: relative;
    text-align: center;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}
.settings-button-container {
    position: absolute;
    top: 8px;
    right: 8px;
}
.profile-card h2 {
    margin-bottom: 8px;
}

.profile-card p {
    margin: 4px 0;
}

.profile .bio {
    margin-top: 16px;
    text-align: center;
}

.password-change {
    max-width: 400px;
    margin-top: 20px;
}

.log-data {
    max-width: 600px;
    margin-top: 20px;
}

.log-data button {
    margin-bottom: 10px;
}

.log-data .ant-list-item {
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 8px 12px;
    margin-bottom: 8px;
}

.registration-date {
    font-size: 12px;
    color: #888;
}
/* superuser add user component style start */
.add-user-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    background-color: #f5f5f5;
}

.add-user-form {
    max-width: 720px;
    width: 100%;
    padding: 24px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.add-user-form h2 {
    text-align: center;
    margin-bottom: 24px;
    font-size: 24px;
    color: #1890ff;
}
/* superuser add user component style end */

/* execution style start */
.execution-item {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 8px;
}
.execution-item-card {
    padding: 3px 5px;
    border-radius: 8px;
}
.ex-item-point {
    margin: 2px 0;
}
.ex-item-point-b {
    font-weight: 500;
}
.execution-item-error > div > .ex-item-point {
    color: #fff;
}
/* execution style end */

/* ticket page style start */
/* TicketForm.css */
.ticket-form-container {
    max-width: 400px;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.ticket-form-container h2 {
    text-align: center;
    margin-bottom: 24px;
    font-size: 24px;
    color: #1890ff;
}

.ticket-form-container .ant-form-item {
    margin-bottom: 24px;
}

.ticket-form-container .ant-form-item-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
}

.ticket-form-container .ant-select {
    width: 100%;
}

.ticket-form-container .ant-btn {
    width: 100%;
}
/* TicketTable.css */
.ticket-table-container {
    max-width: 800px;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.ticket-table-container h2 {
    text-align: center;
    margin-bottom: 24px;
    font-size: 24px;
    color: #1890ff;
}

.ticket-table-container .ant-table {
    border: 1px solid #e8e8e8;
    border-radius: 8px;
}

.ticket-table-container .ant-table th,
.ticket-table-container .ant-table td {
    border: 1px solid #e8e8e8;
}

.ticket-table-container .ant-table-thead > tr > th {
    background-color: #f5f5f5;
    text-align: center;
}

.ticket-table-container .ant-table-tbody > tr > td {
    text-align: center;
}

.ticket-table-container .ant-btn {
    margin-right: 8px;
}
/* TicketPage.css */
.ticket-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.ticket-page-container h1 {
    text-align: center;
    margin-bottom: 24px;
    font-size: 36px;
    color: #1890ff;
}

.ticket-form-section {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
}

.ticket-table-section {
    width: 100%;
    max-width: 800px;
}
/* ticket page style end */

/* material allocate page style */
.file-upload-container {
    display: flex;
    align-items: center;
}

.custom-file-upload {
    background-color: #1890ff;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.upload-icon {
    margin-right: 5px;
    width: 20px; /* Adjust the width and height to fit your icon size */
    height: 20px;
}

#file-upload {
    display: none;
}

.selected-file-name {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
}

/* material movement page */
.input-container {
    margin-bottom: 20px;
}

.required-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #dc2020;
}
/* collapse style */
.ant-collapse-header {
    /* background-color: rgb(237, 229, 229); */
    padding: 5px !important;
    border-radius: 10px !important;
}
/*======================
    404 page
=======================*/
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f8f8;
}

.content {
    text-align: center;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.largeText {
    font-size: 5rem;
    margin-bottom: 0.5rem;
    color: #ff5733;
}

.mediumText {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.smallText {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: #555;
}

.link {
    text-decoration: none;
    color: #007bff;
    font-weight: 500;
    transition: color 0.2s;
}

.link:hover {
    color: #0056b3;
}
.dropdown-content {
    z-index: 9999999999 !important;
}

/* DotLoader.css */
.dot-loader {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #a9a5a5;
    animation: bounce 0.6s infinite alternate;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}

/* game style */
.show-game-container {
    width: 100%;
    text-align: center;
    margin: -40px 0 10px 0;
}
.show-game-button {
    border: none;
    text-decoration: underline;
    color: rgb(116, 116, 116);
    background: none;
}
.tie-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.game-board {
    display: grid;
    grid-template-columns: repeat(3, 80px);
    gap: 10px;
}

.game-cell {
    width: 80px;
    height: 80px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    font-size: 36px;
    font-weight: bold;
    cursor: pointer;
}

.game-cell.X {
    color: blue;
}

.game-cell.O {
    color: red;
}

.game-reset-button {
    margin: 10px 0;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
}
.winner {
    display: none;
    font-size: 24px;
    margin: 10px;
}

.winner.show {
    display: block;
    animation: gameFadeIn 2s;
}

/* New styles for the score display */
.scoreboard {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}

/* Add keyframes for fadeIn animation */
@keyframes gameFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* custom legend style start */
.legend-container {
    /* display: flex;
  align-items: center;
  gap: 5px; */
}
.legend-right-section {
    display: flex;
    gap: 5px;
    align-items: center;
}
.legend-icon {
    display: flex;
    width: 10px;
    height: 8px;
    align-items: center;
    border-radius: 2px;
}
.legend-text {
    color: #0050a4;
    margin: 0;
    font-weight: 600;
    font-size: 7px;
}
/* custom legend style end */

/* chart style start */
.strike-rate-item {
    font-size: 79px;
    font-weight: 900;
    font-family: "Poppins";
    color: #4d4d4d;
    padding: 18px 0;
}
.compliance-chart-container {
    width: 185px;
    height: 185px;
    margin: 0 auto;
    /* background-color: rgb(220, 220, 220); */
}
.single-legend-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px 0;
}
.chart-round-box {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
}
.chart-button-container {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 10px 0 10px;
}
.chart-button-container2 {
    position: absolute;
    top: 0;
    right: 130px;
    padding: 10px 10px 0 10px;
}
.common-chart-button {
    color: #fff;
    font-weight: 500;
    padding: 2px 3px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}
.ant-tooltip-inner {
    background: linear-gradient(to right, #493e96, #616ada) !important;
}
.tooltip-custom-text {
    display: block;
    font-weight: 700;
    font-size: 16px;
}
.linearText {
    /* background: -webkit-linear-gradient(#eee, #333); */
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}
/* chart style end */
/* attendance style start */
.attendance-overview-container {
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 0px 5px #eee;
}
.attendance-info-txt {
    margin: 0;
    font-weight: 500;
}

.attendance__tabs > .ant-tabs-nav {
    display: none !important;
}

/* attendance style end */
/* route plan style start */
.route-plan-footer {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 0 0 10px 10px;
}
.route-plan-footer-item {
    display: flex;
    align-items: end;
    gap: 2px;
}
/* route plan style end */

/* ai table style */
.ai-table-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-auto-flow: dense;
}

#drag-container,
#spin-container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(-10deg);
    transform: rotateX(-10deg);
}

#drag-container img,
#drag-container video {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 200px;
    font-size: 50px;
    text-align: center;
    -webkit-box-shadow: 0 0 8px #fff;
    box-shadow: 0 0 8px #fff;
    -webkit-box-reflect: below 10px
        linear-gradient(transparent, transparent, #0005);
}

#drag-container img:hover,
#drag-container video:hover {
    -webkit-box-shadow: 0 0 15px #fffd;
    box-shadow: 0 0 15px #fffd;
    -webkit-box-reflect: below 10px
        linear-gradient(transparent, transparent, #0007);
}

#drag-container p {
    font-family: Serif;
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotateX(90deg);
    transform: translate(-50%, -50%) rotateX(90deg);
    color: #fff;
}

#ground {
    width: 900px;
    height: 900px;
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotateX(90deg);
    transform: translate(-50%, -50%) rotateX(90deg);
    background: -webkit-radial-gradient(
        center center,
        farthest-side,
        #9993,
        transparent
    );
}

#music-container {
    position: absolute;
    top: 0;
    left: 0;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    to {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    to {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@-webkit-keyframes spinRevert {
    from {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
    to {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
}
@keyframes spinRevert {
    from {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
    to {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
}

/* salary evaluation */

.not_eligible,
.not_eligible > td {
    background-color: #ff4d4f !important;
    color: white;
}

/* ai result panel */
.ai__result__panel > .ant-collapse-header {
    display: none !important;
}

.ant-collapse-content.ant-collapse-content-active,
.ant-collapse-content.ant-collapse-content-inactive.ant-collapse-content-hidden,
.anticon.anticon-caret-right.collapse__icon > svg {
    transition: ease-out !important;
    transition-duration: 0.2s !important;
    transition-property: all !important;
}

/* Audit section */

.ant-btn-primary {
    background-color: #1677ff;
}

.compliance-modal .ant-modal-content {
    padding: 24px;
}

.compliance-modal .ant-modal-header {
    margin-bottom: 20px;
}

.compliance-modal .ant-modal-footer {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #f0f0f0;
}

.ant-select-selection-placeholder {
    color: #666;
}

/* salary evaluation modal segmented */
.ant-segmented.salary__evaluation {
    background-color: gainsboro;
}

.ant-segmented.salary__evaluation
    > .ant-segmented-group
    > .ant-segmented-item.ant-segmented-item-selected
    > .ant-segmented-item-label[title="YES"] {
    background-color: green;
    border-radius: 3px;
}

.ant-segmented.salary__evaluation
    > .ant-segmented-group
    > .ant-segmented-item.ant-segmented-item-selected
    > .ant-segmented-item-label[title="NO"] {
    background-color: red;
    border-radius: 3px;
}
